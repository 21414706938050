import React from 'react';

function Contact() {
    return (
        <div className='header-text pad-top'>
            Contact PostNowhere
        </div>
    );
  }
  
  export default Contact;
