
import Post from "../Components/Views/Post";

function Home() {
    return (
        <div className='center'>
            <div className='header-text-smaller pad-top bold'>
                Welcome to Post Nowhere
            </div>
            <div className='subheader-text italic'>
                are you tired of social media? us too
            </div>
            <div className="">
                <Post message={"This is a test"} show={false} />
            </div>

            <div>
            <div className="pad-top">
                <i>note:</i> Upcoming releases will allow you to display your thoughts, anonymously, if you choose.
            </div>
          </div>

        </div>
    );
  }
  
  export default Home;
