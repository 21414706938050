import React from 'react';
import logo from '../../Images/logo.png';

// import { Link} from 'react-router-dom';

function Header() {
    return (
        <div>
            <img src={logo} alt="Post Nowhere Logo" height={70} />
            <div>
                    {/* <Link to='/'>Home</Link>|
                <Link to='/about'>About</Link>|
                <Link to='/contact'>Contact</Link> */}
            </div>
        </div>
    );
  }
  
  export default Header;
