import './App.css';
import Footer from './Components/Views/Footer';
import Header from './Components/Views/Header';
import Main from './Components/Main';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // const showToast = () => {
  //   toast("I am Tostify!")
  // };
  console.log(process.env.REACT_APP_POST_NOWHERE_API)
  
  return (
    <div>
      <div className="container">
        <ToastContainer />
        <Header />
        <Main />
        
      </div>
      <div> 
        <Footer />
      </div>
    </div>
  );
}

export default App;
