import React from 'react';
import { Button } from 'react-bootstrap'
// import http from "../Libs/AxiosHttp";
import axios from "axios";
import { toast } from 'react-toastify';

type ModalProps = {
    message: string;
    show: boolean;
  };

type ModalState = {
    show: boolean;
};

class Post extends React.Component<ModalProps, ModalState> {  

    constructor(props: ModalProps | Readonly<ModalProps>) {
        // pass props up
        super(props);

        this.state = {
            show: false,
        };
        // const config = {
        //     headers:{
        //         "Access-Control-Allow-Origin": "true"
        //     }
        // }

        this.getAll();

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    getAll = () => {

        axios.get(`${process.env.REACT_APP_POST_NOWHERE_API}post`, {
            headers: { 
                "Access-Control-Allow-Origin": true,
                mode: 'cors'
             }
        })
        .then(res=> {
        })
        .catch(err=> {
        })
    }
        
    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const messageElement = event.currentTarget.elements.namedItem('message') as HTMLInputElement

        const message = messageElement.value;

        // reset messageElement 
        messageElement.value = "";

        axios.post(`${process.env.REACT_APP_POST_NOWHERE_API}post`, {
            message: message,
            display_name: "",
            public_view: false
        })
        .then(res=> {
            console.log('Poste...');
            console.log(res);
        })
        .catch(err=> {
            console.log(err);
        })

        this.setState({show: true});

        this.showToast();
    }

    showToast = () => toast.info('Post Successful');
    
    render(): JSX.Element {
        return (
            <div>
                <div className='center pad-top-small'>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group pad-top-small">
                            <div className='input-group flex-nowrap'>
                                <textarea id="message" className='form-control' />
                                <Button type="submit" className='btn btn-primary'>
                                    Tell the World!
                                    <br />
                                    or, No One!
                                </Button>
                            </div>
                            <div className='col col-md-6'>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Post;
