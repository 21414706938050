import React from 'react';

function About() {
    return (
        <div className='header-text pad-top'>
            About PostNowhere
        </div>
    );
  }
  
  export default About;
